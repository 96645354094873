<template>
	<section class="almacen-equipos px-3 container-fluid overflow-auto custom-scroll mt-2">
        <navbar-admin>
			<el-tooltip content="Editar" placement="bottom" effect="light" visible-arrow>
                <button 
					class="btn btn-square32-5d ml-2" 
					:class="permitAction('almacen.equipos.editar') ? 'cr-pointer' : 'cr-not-allowed'" 
					@click="functionPermitAction('almacen.equipos.editar', openEditarEquipo)"
				>
                    <i class="icon-pencil-outline text-white" />
                </button>
            </el-tooltip>
            <el-tooltip content="Eliminar" placement="bottom-end" effect="light" visible-arrow>
                <button class="btn btn-square32-5d ml-2"
					:class="permitAction('almacen.equipos.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
					@click="functionPermitAction('almacen.equipos.eliminar', openDeleteEquipo)"
				>
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
		</navbar-admin>
		<div class="row">
			<div class="col-auto">
				<img :src=" detalleCabecera.imagen | helper-storage" alt="" class="wh-151 border-dd br-4">
			</div>
			<div class="col">
				<p class="f-18 f-700">{{ detalleCabecera.nombre }}</p>
				<div class="row my-3">
					<div class="col-3 col-lg-3 col-xl-auto mb-2 my-lg-auto">
						<div class="d-middle">
							<p class="f-500 f-12">
								Precio:
								<span class="f-300"> {{ formatoMoneda(detalleCabecera.precio) }} </span>
							</p>
							<!-- <i class="icon-alert text-orange f-18 ml-1" /> -->
						</div>
					</div>
					<div class="col-3 col-lg-3 col-xl-auto mb-2 my-lg-auto">
						<p class="f-500 f-12">
							Etapas:
							<span class="f-300"> {{ detalleCabecera.etapas }} </span>
						</p>
					</div>
					<div class="col-3 col-lg-3 col-xl-auto mb-2 my-lg-auto">
						<p class="f-500 f-12">
							Materiales:
							<span class="f-300"> {{ detalleCabecera.materiales }} </span>
						</p>
					</div>
					<div class="col-3 col-lg-3 col-xl-auto mb-2 my-lg-auto">
						<p class="f-500 f-12">
							Horas hombre:
							<span class="f-300"> {{ detalleCabecera.horas_hombre }} </span>
						</p>
					</div>
				</div>
				<div class="row my-3">
					<div class="col-12 col-lg-12 mb-2 my-lg-auto">
						<p class="f-12 lh-15">{{ detalleCabecera.descripcion == '' ? 'Falta descripcion' : detalleCabecera.descripcion }}</p>
					</div>
				</div>
				<div class="row my-3">
					<div class="col-4 col-lg-4 col-xl-auto mb-2 my-lg-auto">
						<p class="f-500 f-12">
							Producto:
							<span class="f-300"> {{ detalleCabecera.nombre }} </span>
						</p>
					</div>
					<div class="col-4 col-lg-4 col-xl-auto mb-2 my-lg-auto">
						<p class="f-500 f-12">
							Categoría:
							<span class="f-300"> {{ detalleCabecera.categoria }} </span>
						</p>
					</div>
					<div class="col-4 col-lg-4 col-xl-auto mb-2 my-lg-auto">
						<p class="f-500 f-12">
							Subcategoría:
							<span class="f-300"> {{ detalleCabecera.subCategoria == null ? 'Sin subcategoría' : detalleCabecera.subCategoria }} </span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-8">
				<div class="d-flex justify-content-between my-4">
					<p class="text-muted f-600">Selecciona la cantidad de configuraciones por etapa</p>
					<el-input v-model="buscar" placeholder="Buscar etapa" size="small" class="w-25"/>
				</div>

				<el-collapse v-model="activeName" class="phases-list">
					<el-collapse-item v-for="(etapa, index) in etapasConfiguraciones.filter(e => e.etapa.toLowerCase().includes(buscar.toLowerCase()))" :key="index" :name="`${index}`">
						<template slot="title">
							<div class="d-flex w-100">
								<p class="pl-2 text-5d f-600"> {{ etapa.etapa }} </p>
								<div class="d-middle ml-auto icon-option">
									<p class="text-muted">{{ countSelect(etapa.configuraciones) }}/{{ etapa.cantidad_configuraciones }} </p>
								</div>
							</div>
						</template>
						<div class="row justify-content-center">
							<div class="col-11">
								<div v-for="(conf, idx) in etapa.configuraciones" :key="idx" 
									class="shadow29 bg-white text-5d br-4 px-2 py-1 f-14 mb-2">
									<div class="row f-14 mx-0">
										<div class="col-6 d-flex my-auto tres-puntos">
											<el-checkbox 
												v-model="conf.seleccionada" 
												:true-label="1" 
												:false-label="0"
												:class="permitAction('almacen.equipos.seleccionar.etapa') ? 'cr-pointer' : 'cr-not-allowed'" 
												@change="functionPermitAction('almacen.equipos.seleccionar.etapa', saveConfig, [etapa.configuraciones, etapa])"
											/>
											<p class="tres-puntos f-14 ml-2"> {{ conf.configuracion }} </p>
										</div>
										<div class="col-auto ml-auto">
											<div class="d-flex">
												<i class="icon-cash-multiple f-18 mr-2" />
												<p class="my-auto"> {{ formatoMoneda(conf.valor) }} </p>
											</div>
										</div>
										<div class="col-auto">
											<div class="d-flex">
												<i class="icon-account-clock f-18" />
												<p class="my-auto"> {{ conf.horas_hombre }}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</div>
		<modal-eliminar 
			ref="modalEliminar" 
			title="Eliminar equipo modelo" 
			mensaje="¿Está seguro que quiere eliminar este equipo modelo?"
			@delete="deleteEquipo"
        />
		<modal-agregar-equipo ref="modalAgregarEquipo" />
	</section>
</template>

<script>
import modalAgregarEquipo from './partials/modalAgregarEquipo'
import { mapActions, mapGetters } from 'vuex';
export default {
	name: 'detalleEquipo',
	components: {
		modalAgregarEquipo,
	},
    data(){
        return{
			checked: true,
			buscar: '',
			idEquipo: this.$route.params.id,
			activeName: []
		}
    },
    async created(){
        this.getBreadcumbs([ 'almacen.main', 'almacen.equipos' ]);
		// await this.Action_get_detalle_equipos_modelos(this.idEquipo)
		await this.Action_get_selects_categorias()
		await this.Action_get_etapas_configuraciones({id_equipo_modelo:this.idEquipo})
		await this.Action_get_detalle_cabecera(this.idEquipo)
		this.spliceBreadcumbs([
            {
                position: 2,
                breadcumb: {
                    name: this.detalleCabecera.nombre,
                    route: 'almacen.equipos',
                }
            },
        ])
        this.replaceBreadcumb({ position: 3, breadcumb: this.$route.name })
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            detalleEquipos: 'almacen/equiposModelos/detalleEquipos',
            etapasConfiguraciones: 'almacen/equiposModelos/etapasConfiguraciones',
            detalleCabecera: 'almacen/equiposModelos/detalleCabecera',
        })
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            Action_get_detalle_equipos_modelos: 'almacen/equiposModelos/Action_get_detalle_equipos_modelos',
            Action_get_selects_categorias: 'selects/selects/Action_get_selects_categorias',
            Action_delete_equipo_modelo: 'almacen/equiposModelos/Action_delete_equipo_modelo',
            Action_guardar_config_equipo: 'almacen/equiposModelos/Action_guardar_config_equipo',
            Action_get_etapas_configuraciones: 'almacen/equiposModelos/Action_get_etapas_configuraciones',
			spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
			replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
            Action_get_detalle_cabecera: 'almacen/equiposModelos/Action_get_detalle_cabecera',
        }),
        openEditarEquipo(){
			this.$refs.modalAgregarEquipo.toggle(this.detalleCabecera)
        },
		openDeleteEquipo(){
			this.$refs.modalEliminar.toggle()
		},
		async deleteEquipo(){
			await this.Action_delete_equipo_modelo(this.idEquipo)
			this.$router.push({name: 'almacen.equipos'})
		},
		async saveConfig(config, etapa){
			const id_almacen_etapas_configuraciones = config.filter( el => el.seleccionada == 1).map(el => el.id)
			const payload = {
				id_almacen_etapas_configuraciones,
				id_etapa: etapa.id
			}
			await this.Action_guardar_config_equipo({idEquipo: this.idEquipo, payload})
			// await this.Action_get_detalle_equipos_modelos(this.idEquipo)
			await this.Action_get_etapas_configuraciones({id_equipo_modelo:this.idEquipo})
			await this.Action_get_detalle_cabecera(this.idEquipo)

		},
		countSelect(conf){
			const seleccionado = conf.filter(el => el.seleccionada == 1)
			return seleccionado.length
		}
    }

}
</script>

<style lang="scss" scoped>
    .almacen-equipos{
        height: calc(100vh - 240px);
    }
	p{
		cursor: default;
	}
</style>